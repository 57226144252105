import React from "react";
import { Helmet } from "react-helmet";
import useSiteMetadata from "../hooks/useSiteMetadata";
import ShareImage from "../images/share.png";

const Layout = ({ children, location: { pathname } }) => {
  const { siteUrl, title, author, description } = useSiteMetadata();

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=fallback"
          rel="stylesheet"
        />
        <title>{title}</title>
        <meta name="author" content={author} />
        <meta name="description" content={description} />
        <meta property="og:url" content={`${siteUrl}${pathname}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={`${siteUrl}${ShareImage}`} />
      </Helmet>
      {children}
    </>
  );
};

export default Layout;
